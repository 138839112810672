<template>
  <div class="s-master-c">
    <div class="s-master-c__container v2-container">
      <div class="s-master-c__left">
        <h2 class="s-master-c__title title-secondary">
          Мастер-классы от&nbsp;крутых&nbsp;экспертов
        </h2>

        <p class="s-master-c__tx text-main">
          Ребенок не&nbsp;только узнает про&nbsp;новую
          профессию из&nbsp;первых уст,
          но&nbsp;и&nbsp;получит пошаговый план как&nbsp;войти в&nbsp;каждую
          отрасль и&nbsp;что&nbsp;конкретно нужно&nbsp;делать
        </p>
      </div>

      <div class="s-master-c__rigth">
        <div class="s-master-c__persons persons">
          <div class="persons__decor decor">
            <img
              id="mc-star-top"
              loading="lazy"
              src="/v2/school/master-classes/star-top.png"
              alt="star"
              class="decor__item decor__item--top"
            >

            <img
              id="mc-star-bottom"
              loading="lazy"
              src="/v2/school/master-classes/star-bottom.png"
              alt="star"
              class="decor__item decor__item--bottom"
            >

            <img
              id="mc-star-left"
              loading="lazy"
              src="/v2/school/master-classes/star-left.png"
              alt="star"
              class="decor__item decor__item--left"
            >
          </div>

          <div class="persons__card card card--blockchain">
            <div class="card__inner">
              <div class="card__circle" />

              <div class="card__position card__position--blockchain">
                Инженер по технологии блокчейн
              </div>

              <div class="card__wrap card__wrap--blockchain">
                <div class="card__img-wrap">
                  <img
                    loading="lazy"
                    src="/v2/school/master-classes/blockchain.webp"
                    alt="blockchain"
                    class="card__img"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="persons__card card card--vr">
            <div class="card__inner">
              <div class="card__circle" />

              <div class="card__position card__position--vr">
                Специалист по кибербезопасности
              </div>

              <div class="card__wrap card__wrap--vr">
                <div class="card__img-wrap">
                  <img
                    loading="lazy"
                    src="/v2/school/master-classes/vr.webp"
                    alt="vr"
                    class="card__img"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="persons__card card card--cyber">
            <div class="card__inner">
              <div class="card__circle" />

              <div class="card__position card__position--cyber">
                Специалист по дополненной реальности
              </div>

              <div class="card__wrap card__wrap--cyber">
                <div class="card__img-wrap">
                  <img
                    loading="lazy"
                    src="/v2/school/master-classes/cyber.webp"
                    alt="cyber"
                    class="card__img"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="s-master-c__slider slider">
          <div class="slider__decor decor">
            <img
              id="mc-star-top-mob"
              loading="lazy"
              src="/v2/school/master-classes/star-top.png"
              alt="star"
              class="decor__item decor__item--top"
            >

            <img
              id="mc-star-bottom-mob"
              loading="lazy"
              src="/v2/school/master-classes/star-bottom.png"
              alt="star"
              class="decor__item decor__item--bottom"
            >

            <img
              loading="lazy"
              src="/v2/school/master-classes/star-left.png"
              alt="star"
              class="decor__item decor__item--left"
            >
          </div>

          <div
            class="slider__card card card--blockchain"
            :class="{
              'card--blockchain--hidden' : curCard !== 0,
              'card--blockchain--active-next' : curCard === 2
            }"
            @click="next"
          >
            <div class="card__inner">
              <div class="card__circle" />

              <div class="card__position card__position--blockchain">
                Инженер по технологии блокчейн
              </div>

              <div class="card__wrap card__wrap--blockchain">
                <div class="card__img-wrap">
                  <img
                    loading="lazy"
                    src="/v2/school/master-classes/blockchain-mob.webp"
                    alt="blockchain"
                    class="card__img"
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="slider__card card card--cyber"
            :class="{
              'card--cyber--active' : curCard === 1,
              'card--cyber--active-next' : curCard === 2
            }"
            @click="next"
          >
            <div class="card__inner">
              <div class="card__circle" />

              <div class="card__position card__position--cyber">
                Специалист по дополненной реальности
              </div>

              <div class="card__wrap card__wrap--cyber">
                <div class="card__img-wrap">
                  <img
                    loading="lazy"
                    src="/v2/school/master-classes/cyber-mob.webp"
                    alt="cyber"
                    class="card__img"
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="slider__card card card--vr"
            :class="{'card--vr--active' : curCard === 2}"
            @click="next"
          >
            <div class="card__inner">
              <div class="card__circle" />

              <div class="card__position card__position--vr">
                Специалист по кибербезопасности
              </div>

              <div class="card__wrap card__wrap--vr">
                <div class="card__img-wrap">
                  <img
                    loading="lazy"
                    src="/v2/school/master-classes/vr-mob.webp"
                    alt="vr"
                    class="card__img"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="slider__controls">
            <button
              class="slider__btn slider__btn--prev"
              @click="prev"
            >
              <img
                loading="lazy"
                class="slider__arrow"
                src="/v2/school/knowledge/arrow.svg"
                alt="arrow"
              >
            </button>

            <button
              class="slider__btn slider__btn--next"
              @click="next"
            >
              <img
                loading="lazy"
                class="slider__arrow"
                src="/v2/school/knowledge/arrow.svg"
                alt="arrow"
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolMasterClasses',

  data: () => ({
    curCard: 0,
  }),
  methods: {
    next() {
      this.curCard += 1;

      if (this.curCard > 2) {
        this.curCard = 0;
      }
    },

    prev() {
      this.curCard -= 1;

      if (this.curCard < 0) {
        this.curCard = 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.s-master-c {
  overflow: hidden;

  &__container {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    padding-top: 115px;

    max-width: 1272px;

    @media only screen and (max-width: $school-desktop) {
      flex-direction: column;
    }

    @include media-down(tablet) {
      padding-top: 0;
    }
  }

  &__left {
    padding-top: 135px;

    max-width: 505px;

    @media only screen and (max-width: $school-desktop) {
      margin: 0 auto;
    }

    @include media-down(tablet) {
      padding-top: 0;
    }
  }

  &__title {
    margin-bottom: 20px;

    @media only screen and (max-width: $school-desktop) {
      text-align: center;
    }

    @include media-down(tablet) {
      margin-bottom: 12px;

      text-transform: initial;
    }
  }

  &__tx {
    @media only screen and (max-width: $school-desktop) {
      text-align: center;
    }

    @include media-down(tablet) {
      font-weight: 600;
    }
  }

  &__rigth {
    @media only screen and (max-width: $school-desktop) {
      display: flex;
      justify-content: center;

      padding-top: 110px;
    }
  }

  &__persons {
    width: 100px;
    height: 700px;

    @media only screen and (max-width: $school-desktop) {
      left: 240px;
    }
  }
}

.persons {
  position: relative;

  @include media-down(tablet) {
    display: none;
  }
}

.decor {
  position: relative;
  z-index: 5;

  &__item {
    position: absolute;

    &--top {
      top: -120px;
      right: 190px;

      width: 55.397px;
      height: 55.397px;
    }

    &--bottom {
      right: 11px;
      bottom: -496px;

      width: 55.397px;
      height: 55.397px;
    }

    &--left {
      top: 10px;
      left: -580px;

      width: 88px;
      height: 88px;
    }
  }
}

.card {
  position: absolute;

  padding: 25px;

  width: 280px;
  height: 400px;

  overflow: hidden;

  border-radius: 20px;
  border: 2px solid $black;

  background: $white-color;

  box-shadow: 4px 4px 0 0 $black;

  cursor: pointer;

  transition: all 0.4s ease-out, z-index 0s 0.2s;

  @include media-down(tablet) {
    position: absolute;
    left: 50%;

    padding: 8px 12px 16px;

    width: 250px;
    height: 333px;

    border-radius: 16px;

    transform-origin: bottom left;
  }

  @media (hover: hover) {
    &:hover {
      @include media-up(tablet) {
        z-index: 4;
      }
    }
  }

  &--blockchain {
    @include media-up(tablet) {
      z-index: 2;
      top: 29px;
      left: -532px;

      transform: rotate(-4deg);
    }

    @include media-down(tablet) {
      z-index: 3;

      transform: rotate(0) translateX(-50%);
    }

    &--hidden {
      z-index: 1;

      transform: rotate(-15deg) translate(-59%, 3%);
    }

    &--active-next {
      z-index: 1;

      transform: rotate(-30deg) translate(-59%, 3%);
    }
  }

  &--vr {
    @include media-up(tablet) {
      z-index: 1;
      top: 166px;
      left: -312px;

      transform: rotate(2deg);
    }

    @include media-down(tablet) {
      z-index: 1;

      transform: rotate(30deg) translate(-33%, 4%);
    }

    &--active {
      z-index: 3;

      transform: rotate(0) translateX(-50%);
    }
  }

  &--cyber {
    @include media-up(tablet) {
      top: -43px;
      right: -61px;

      transform: rotate(4deg);
    }

    @include media-down(tablet) {
      z-index: 2;

      transform: rotate(15deg) translate(-39%, 3%);
    }

    &--active {
      z-index: 3;

      transform: rotate(0) translateX(-50%);
    }

    &--active-next {
      z-index: 2;

      transform: rotate(-15deg) translateX(-50%);
    }
  }

  &__circle {
    margin: 0 auto 8px;

    width: 40px;
    height: 40px;

    border: 2px solid $black;
    border-radius: 50%;

    background-color: $gray-bg;

    @include media-down(tablet) {
      margin-bottom: 4px;

      width: 32px;
      height: 32px;
    }
  }

  &__position {
    margin-bottom: 20px;

    font-size: 20px;
    font-weight: 900;
    line-height: 19px;
    letter-spacing: -0.2px;
    text-transform: uppercase;

    @include media-down(tablet) {
      margin-bottom: 8px;

      letter-spacing: -0.52px;

      font-size: 26px;
      line-height: 24.7px;
    }

    &--blockchain {
      color: #f5b945;
    }

    &--vr {
      color: #89c053;
    }

    &--cyber {
      color: #4a88da;
    }
  }

  &__wrap {
    position: relative;

    margin-bottom: 20px;

    @include media-down(tablet) {
      margin-bottom: 12px;
    }

    &--blockchain {
      &::before {
        background: #f5b945;
      }
    }

    &--vr {
      &::before {
        background: #89c053;
      }
    }

    &--cyber {
      &::before {
        background: #67adff;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 5px;
      right: -4px;

      display: block;

      width: 100%;
      height: 100%;

      border-radius: 14px;
      border: 2px solid $black;

      @include media-down(tablet) {
        border-radius: 7px;
      }
    }
  }

  &__img-wrap {
    position: relative;

    border-radius: 14px;
    border: 2px solid $black;

    overflow: hidden;

    @include media-down(tablet) {
      border-radius: 7px;
    }
  }

  &__img {
    display: block;

    margin: -1px 0 0 -1px;

    width: 100%;

    object-fit: cover;

    @include media-down(laptop) {
      height: 185px;
    }
  }
}

.slider {
  position: relative;

  display: none;

  height: 500px;

  @include media-down(tablet) {
    display: block;
  }

  &__controls {
    position: relative;
    z-index: 11;
    top: 169px;

    width: 347px;
  }

  &__btn {
    position: absolute;
    top: 0;

    margin: 0;
    padding: 0;

    width: 60px;
    height: 60px;

    border: none;
    border-radius: 50%;

    cursor: pointer;

    @include media-down(tablet) {
      width: 44px;
      height: 44px;
    }

    &--prev {
      left: 0;

      filter: drop-shadow(-2px -2px 0 $black);

      transform: rotate(180deg);
    }

    &--next {
      right: 0;

      filter: drop-shadow(2px 2px 0 $black);
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;
  }
}
</style>
